.vidyo-chat {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.vidyo-chat-text-container {
  display: flex;
  margin-bottom: 5px;
}

.vidyo-chat-text-container.mine {
  justify-content: flex-end;
}

.vidyo-chat .vidyo-chat-sender-and-text-box {
  display: flex;
  flex-direction: column;
}

.vidyo-chat .vidyo-chat-sender-and-text-box .vidyo-chat-sender {
  font-size: 11px;
  text-align: left;
  margin-bottom: 3px;
}

.vidyo-chat .vidyo-chat-sender-and-text-box .vidyo-chat-sender.mine {
  text-align: right;
}

.vidyo-chat .vidyo-chat-sender-and-text-box .vidyo-chat-text {
  max-width: 180px;
  padding: 5px 10px;
  margin: 0;
  overflow-wrap: break-word;
  color: white;
  text-align: left;
  align-self: flex-start;
  background-color: var(--green);
}

.vidyo-chat .vidyo-chat-sender-and-text-box .vidyo-chat-text.mine {
  /* color: white; */
  text-align: right;
  align-self: flex-end;
  background-color: var(--gray);
}