:root {
  /* PRIMARY PALETTE */
  --dark-green: #034C1F;
  --green: #35702A;
  --bright-green: #CBD52F;

  /* SECONDARY PALETTE */
  --blue: #5EBAB4;
  --dark-blue: #005B7C; 
  --orange: #DAAA00;
  --purple: #80276C;
  --gray: #A7ABAA;
  --dark-gray: #63666A;
  --red: #B40C2F;
  --black: #000000;

  /* OTHERS */
  --dark-orange: #f3600b;
  --text-gray: #5e5e5e;
  --light-gray: #ddd;
  --error: red;
  --light-blue: #5ebfb9;
  --border-color: #d9d9d9;
  --transition: 0.3s;
  --container-padding: 10px;
  --bottom-border: 0px 10px 15px -8px;
  --margin: 10px;
  --scroll: #838991;
  --scroll-hover: #6f747a;
  --hover-green: #22672a;
}

:root {
  --header-height: 56px;
}

@media only screen and (max-width: 600px) {
  :root {
    --header-height: 35px;
  }
}

html, body {
  margin: 0;
  padding: 0;
  background-color: #ececec;
  color: var(--text-gray);
}

.App {
  text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-gray);
}

textarea {
  border-radius: 5px;
  border: 1px #d9d9d9 solid;
}

.button {
  color: white;
  background-color: var(--green);
  border-color: var(--green);
  border-radius: 0;
}

.button:hover {
  color: white;
  background-color: var(--dark-green);
  border-color: var(--dark-green);
}