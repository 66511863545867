.appointment-item {
  text-align: left;
  padding: 10px;
}

.appointment-item .appointment-item-info {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.appointment-item .appointment-item-member-name {
  font-size: large;

}
.appointment-item .appointment-item-date {
  font-size: large;
}

.appointment-item .cancelled-status {
  color: var(--red);
}

.appointment-item .appointment-item-info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.appointment-item .appointment-timeline {
  padding: 10px 0;
  position: relative;
}

.appointment-item .appointment-item-label {
  font-weight: 500;
  margin-right: 10px;
}

.appointment-item .appointment-item-link {
  padding: 0;
}

.appointment-item .appointment-item-value {
  
}

.appointment-item .appointment-item-member-link {
  word-wrap: break-word;
}

.appointment-item .appointment-item-resend-email {
  margin: 10px;
  margin-left: 0;
}