.dropbtn {
  background-color: var(--orange);
  color: white;
  /* padding: 16px;
  font-size: 16px; */
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: var(--dark-orange);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  right: 0;
}

.dropdown-content a {
  color: #686565;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover { background-color: #ddd; }

.show { display: block; }